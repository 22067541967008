@font-face {
  font-family: "FontAwesome";
  src: url(../fonts/fontawesome-webfont.ttf) format("truetype");
}

body, h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', 'Open Sans', serif ! important;
}

body.light-login {
  // background: url("../../images/background01.jpg") repeat scroll 0 0;
  background: #FBF8F4;
}

:root {
  --star-size: 20px;
  --star-color: #fff;
  --star-background: #fc0;
}


.rating_stars {
  --percent: calc(var(--rating) / 5 * 100%);

  display: inline-block;
  font-size: var(--star-size);
  font-family: Times; // make sure ★ appears correctly
  line-height: 1;

  &::before {
    content: '★★★★★';
    letter-spacing: 3px;
    background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}